import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import lobbyzeLogo from "../assets/logo_lobbyze.svg"
import slotpokerLogo from "../assets/logo-slotpoker.png"

import Layout from "../components/Layout";
import {
  Container,
  Box,
  InnerBox,
  Title,
  Label,
  Input,
  Button,
  LogoLobbyze,
  LogoSlotPoker,
  FieldsetName,
  FieldsetPass,
  EyePassIcon,
  LockPassIcon,
  VerticalExpander,
  ErrorSpan,
} from "../styles/globalStyles";
import {
  Body,
  JaCadastrado
} from "../styles/loginStyles";

import { GlobalContext } from '../context/globalContext'
import { generateGtagEvent } from '../misc/analytics'
import { StdValidations, useForm } from '../misc/formHook'
import { Popup } from '../misc/popup'

import { confirmRecover, recover } from '../misc/endpoint'

const Recover = () => {

  const location = useLocation()

  const [ code, setCode ] = useState(null)

  useEffect(() => {
    if (location.search) {
      const c = new URLSearchParams(location.search).get('code')
      setCode(c)
    }
  }, [ location ])

  const { setPopup } = useContext(GlobalContext)

  const { t } = useTranslation()

  const { values, errors, submit, createField } = useForm(async () => {
    document.activeElement.blur()
    setPopup(Popup.LOADING)
    const [ status, finish ] = await (
      code == null
      ? recover(values.username)
      : confirmRecover(code, values.password)
    )
    if(status === 200) {
      setPopup(Popup.LOGGED_IN)
      await generateGtagEvent(code == null ? 'recover' : 'recover_confirm')
      finish()
    } else {
      finish().then(e => {
        Popup.ERROR.msg = e
        setPopup(Popup.ERROR)
      })
    }
  })

  const [ showPassword, setShowPassword ] = useState(false)

  return (
    <Layout>
      <Body>
        <Container>
          <Box>
            <InnerBox>
                <div style={{margin: "0 auto", width: "175px", display: "block"}}>
                  <LogoLobbyze src={lobbyzeLogo} />
                  <LogoSlotPoker src={slotpokerLogo} />
                </div>
                <Title>{t('forgotPass.title')}</Title>
                {
                  code == null &&
                    <FieldsetName>
                      <ErrorSpan>{ errors['username'] }</ErrorSpan>
                      <Input
                        type="text"
                        required id="email"
                        {...createField('username', 'username', StdValidations.length(4))} />
                      <Label htmlFor="email">{t('signup.username')}/{t('signup.email')}</Label>
                    </FieldsetName>
                }
                {
                  code != null &&
                    <FieldsetPass>
                      <EyePassIcon pressed={showPassword} onMouseDown={() => setShowPassword(true)} onMouseUp={() => setShowPassword(false)} />
                      <ErrorSpan>{ errors['password'] }</ErrorSpan>
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        required id="password"
                        {...createField('password', 'password', StdValidations.length(6))} />
                      <Label htmlFor="password">{t('signup.password')}</Label>
                    </FieldsetPass>
                }
                {
                  code != null &&
                    <FieldsetPass>
                      <LockPassIcon />
                      <ErrorSpan>{ errors['password-confirm'] }</ErrorSpan>
                      <Input
                        type="password"
                        required id="password-confirm"
                        {...createField('password-confirm', 'password', StdValidations.equalsField('password'))} />
                      <Label htmlFor="password-confirm">{t('signup.confPass')}</Label>
                    </FieldsetPass>  
                }
                <VerticalExpander />
                <Button type="submit" onClick={() => submit()}>{t('send')}</Button>
                <JaCadastrado to="/">{t('back')}</JaCadastrado>
            </InnerBox>
          </Box>
        </Container>
      </Body>
    </Layout>
  );
};

export default Recover;