import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next"
import {useLocation} from 'react-router-dom';

import lobbyzeLogo from "../assets/logo_lobbyze.svg"
import slotpokerLogo from "../assets/logo-slotpoker.png"

import Layout from "../components/Layout";
import {
  Container,
  Box,
  InnerBox,
  Title,
  Label,
  Input,
  Button,
  LogoLobbyze,
  LogoSlotPoker,
  FieldsetEmail,
  FieldsetPass,
  FieldsetName,
  VerticalExpander,
  ErrorSpan,
  EyePassIcon,
  LockPassIcon
} from "../styles/globalStyles";
import {
  Body,
  JaCadastrado
} from "../styles/loginStyles";

import { GlobalContext } from '../context/globalContext';
import { generateGtagEvent } from '../misc/analytics'
import { StdValidations, useForm } from '../misc/formHook'
import { Popup } from '../misc/popup'

import { register } from '../misc/endpoint'

const RegisterEmail = () => {

  const location = useLocation()
  const email = location.state

  const { setPopup } = useContext(GlobalContext)

  const { t } = useTranslation()

  const { values, errors, submit, createField } = useForm(async () => {
    document.activeElement.blur()
    setPopup(Popup.LOADING)
    const [ status, finish ] = await register(values.nick, values.name, values.email, values.password)
    if(status === 200) {
      setPopup(Popup.LOGGED_IN)
      await generateGtagEvent('register')
      finish()
    } else {
      finish().then(e => {
        Popup.ERROR.msg = e
        setPopup(Popup.ERROR)
      })
    }
  })

  const [ showPassword, setShowPassword ] = useState(false)

  return (
    <Layout>
      <Body>
        <Container>
          <Box>
            <InnerBox>
                <div style={{margin: "0 auto", width: "175px", display: "block"}}>
                  <LogoLobbyze src={lobbyzeLogo} />
                  <LogoSlotPoker src={slotpokerLogo} />
                </div>
                <Title>{t('signup.title')}</Title>
                <FieldsetEmail>
                  <ErrorSpan>{ errors['email'] }</ErrorSpan>
                  <Input
                    type="text"
                    required id="email"
                    {...createField('email', 'email', StdValidations.validEmail(), true, email)} />
                  <Label htmlFor="email">{t('signup.email')}</Label>
                </FieldsetEmail>
                <FieldsetName>
                  <ErrorSpan>{ errors['name'] }</ErrorSpan>
                  <Input
                    type="text"
                    required id="nome"
                    {...createField('name', 'name', StdValidations.length(4, 120))} />
                  <Label htmlFor="nome">{t('signup.fullName')}</Label>
                </FieldsetName>
                <FieldsetName>
                  <ErrorSpan>{ errors['nick'] }</ErrorSpan>
                  <Input
                    type="text"
                    required id="nick"
                    {...createField('nick', 'username', StdValidations.length(4, 32))} />
                  <Label htmlFor="nick">{t('signup.username')}</Label>
                </FieldsetName>
                <FieldsetPass>
                  <EyePassIcon pressed={showPassword} onMouseDown={() => setShowPassword(true)} onMouseUp={() => setShowPassword(false)} />
                  <ErrorSpan>{ errors['password'] }</ErrorSpan>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    required id="password"
                    {...createField('password', 'password', StdValidations.length(6))} />
                  <Label htmlFor="password">{t('signup.password')}</Label>
                </FieldsetPass>
                <FieldsetPass>
                  <LockPassIcon />
                  <ErrorSpan>{ errors['password-confirm'] }</ErrorSpan>
                  <Input
                    type="password"
                    required id="password-confirm"
                    {...createField('password-confirm', 'password', StdValidations.equalsField('password'))} />
                  <Label htmlFor="password-confirm">{t('signup.confPass')}</Label>
                </FieldsetPass>
                <VerticalExpander />
                <Button type="submit" onClick={() => submit()}>{t('signupBtn')}</Button>
                <JaCadastrado to="/">{t('alreadyHas')}</JaCadastrado>
            </InnerBox>
          </Box>
        </Container>
      </Body>
    </Layout>
  );
};

export default RegisterEmail;