import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import i18n from "i18next"
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initReactI18next } from "react-i18next";

import GlobalContextProvider from "./context/globalContext";

import localization from './localization.json'

i18n
  .use(initReactI18next)
  .init({
    resources: localization,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

ReactDOM.render(
  
  <React.StrictMode>
		{/* Wrap the App component with the GlobalContextProvider
				created in the previous code snippet */}
    <GlobalContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GlobalContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();