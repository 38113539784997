import styled from "styled-components";
import { Link } from "react-router-dom";
import iconEmail from "../assets/email-entrar.svg"
import iconFacebook from "../assets/facebook.svg"
import iconGoogle from "../assets/google2.svg"

export const NavBar = styled.div`
  background-color: ${(props) => props.theme.secondaryBackground};
  padding: 20px 0;
`;

export const SwitchButton = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.button};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: ${(props) => props.theme.secondaryBackground};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: ${(props) => props.theme.button};
  }

  input:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

export const Body = styled.div`
`;

export const Heading = styled.div`
  font-size: 5em;
  font-weight: 800;
  color: ${(props) => props.theme.text};
`;

export const Para = styled.p`
  font-size: 1.2em;
  line-height: 1.5;
  color: ${(props) => props.theme.text};
  width: 80%;
`;

export const Content = styled.div`
  padding: 10em 0;
`;

export const Divider = styled.h5`
    margin: 40px 0 32px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    &:before {
        content: "";
        background: ${(props) => props.theme.lineBackground};
        display: block;
        width: 100%;
        height: 1px;
    }
    &:after {
        content: "";
        background: ${(props) => props.theme.lineBackground};
        display: block;
        width: 100%;
        height: 1px;
    }
`;

export const DividerText = styled.span`
    text-align: center;
    font: 400 13px/1 "Ubuntu", sans-serif;
    color: rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
    width: 100%;
    display: block;
    margin: 0 18px;
    color: ${(props) => props.theme.secondaryText};
`;

export const ContinueButton = styled.button`
    padding-top: 15px;
    padding-bottom: 18px;
    width: 100%;
    color: #fff;
    border: none;
    border-radius: 6px;
    font: 500 17px/1 "Ubuntu", sans-serif;
    -webkit-transition: 200ms;
    transition: 200ms;
    display: inline-block;
    margin: 8px 0 0 0;
    text-align: center;
    cursor: pointer;
    background: ${(props) => props.theme.backgroundButton};
`;

export const BoxRegisterEmail = styled(Link)`
    display: block;
    margin: 0 8px;
    -webkit-transition: 300ms ease;
    transition: 300ms ease;
    border-radius: 4px;
    height: 50px;
    &:before {
        width: 57px;
        height: 57px;
        display: inline-block;
        content: '';
        -webkit-mask: url(${iconEmail}) no-repeat 50% 50%;
        mask: url(${iconEmail}) no-repeat 50% 50%;
        -webkit-mask-size: contain;
        mask-size: contain;
        background-color: ${(props) => props.theme.text};
    }
`;

export const BoxRegisterFacebook = styled.a`
    display: block;
    margin: 0 8px;
    -webkit-transition: 300ms ease;
    transition: 300ms ease;
    border-radius: 4px;
    height: 50px;
    &:before {
        width: 57px;
        height: 57px;
        display: inline-block;
        content: '';
        -webkit-mask: url(${iconFacebook}) no-repeat 50% 50%;
        mask: url(${iconFacebook}) no-repeat 50% 50%;
        -webkit-mask-size: contain;
        mask-size: contain;
        background-color: ${(props) => props.theme.text};
    }
`;

export const BoxRegisterGoogle = styled.a`
    display: block;
    margin: 0;
    -webkit-transition: 300ms ease;
    transition: 300ms ease;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    text-align: center;
    color: ${(props) => props.theme.text};
    border: 1px solid ${(props) => props.theme.text};
    &:before {
        width: 22px;
        height: 22px;
        margin-top: 13px;
        margin-right: 10px;
        margin-bottom: -5px;
        display: inline-block;
        content: '';
        -webkit-mask: url(${iconGoogle}) no-repeat 50% 50%;
        mask: url(${iconGoogle}) no-repeat 50% 50%;
        -webkit-mask-size: contain;
        mask-size: contain;
        background-color: ${(props) => props.theme.text};
    }
`;

export const Esqueceu = styled(Link)`
    position: absolute;
    top: 0;
    right: 0;
    font: 400 14px/1 "Ubuntu", sans-serif;
    color: ${(props) => props.theme.linkColor};
    -webkit-transition: 300ms ease;
    transition: 300ms ease;
`;

export const JaCadastrado = styled(Link)`
    color: ${(props) => props.theme.linkColor};
    font: 500 16px/1 "Ubuntu", sans-serif;
    margin: 50px 0 0 0;
    display: block;
    text-align: center;
    -webkit-transition: 300ms ease;
    transition: 300ms ease;
`;

export const CreateBtn = styled(Link)`
    color: ${(props) => props.theme.linkColor};
    font: 500 16px/1 "Ubuntu", sans-serif;
    margin: 10px 0 0 0;
    display: block;
    text-align: center;
    -webkit-transition: 300ms ease;
    transition: 300ms ease;
`;

export const PrivacyPolicy = styled.a`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  color: white;
  font-size: 0.6rem;
`
