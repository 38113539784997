import React, { useContext } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";

import { GlobalContext } from "../context/globalContext";

const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    text-decoration: none;
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
  }

  body {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
    background: ${(props) => props.theme.background};
  }
`;

const Layout = ({ children }) => {
  const lobbyzeTheme = {
    background: "#070726",
    secondaryBackground: "rgba(255, 255, 255, 0.05)",
    text: "#FFF",
    secondaryText: "rgba(255, 255, 255, 0.4)",
    button: "#E5E7EB",
    backgroundButton: "linear-gradient(46.71deg, #D190FF 2.27%, #838CFF 73.12%, #42D5DD 150.43%)",
    inputBorder: "1px solid rgba(255, 255 , 255, 0.05);",
    inputFocusBackground: "rgba(255, 255, 255, 0.05)",
    inputIconBackground: "rgb(48,48,91)",
    inputIconBackgroundTranslucent: "rgba(48,48,91, 0.4)",
    lineBackground: "rgba(255,255,255,0.1)",
    displayLobbyzeLogo: "block",
    displaySlotLogo: "none",
    linkColor: "#838CFF"
  };

  const slotPokerProTheme = {
    background: "linear-gradient(to right, #bfdbfe, #f3f4f6)",
    secondaryBackground: "#FFFFFF",
    text: "#111827",
    secondaryText: "#757575",
    button: "#374151",
    backgroundButton: "#4CAF50",
    inputBorder: "1px solid rgba(0,0 ,0,0.05)",
    inputFocusBackground: "rgba(179, 179, 179, 0.288)",
    inputIconBackground: "#80808066",
    lineBackground: "rgba(0,0,0,0.1)",
    displayLobbyzeLogo: "none",
    displaySlotLogo: "block",
    linkColor: "#757575"
  };

  const { theme: themeName } = useContext(GlobalContext);

  let theme
  if(themeName.endsWith("slotpokerpro.com")){
    theme = slotPokerProTheme;
  } else {
    theme = lobbyzeTheme;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>{children}</main>
    </ThemeProvider>
  );
};

export default Layout;