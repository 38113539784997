export async function login(login, password) {
    return fetch('/auth/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            login,
            password
        })
    }).then(response => {
        const callback = () => {
            if(!response.ok) {
                return response.text()
            }
            response.json().then(data => {
                window.location.href = data.url
            })
        }
        return [ response.status, callback ]
    })
    
}

export async function register(login, name, email, password) {
    return fetch('/auth/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            login,
            name,
            email,
            password
        })
    }).then(response => {
        const callback = () => {
            if(!response.ok) {
                return response.text()
            }
            response.json().then(data => {
                window.location.href = data.url
            })
        }
        return [response.status, callback]
    })
}

export async function recover(username) {
    return fetch('/auth/recover', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username,
        })
    }).then(response => {
        const callback = () => {
            if(!response.ok) {
                return response.text()
            }
            response.json().then(data => {
                window.location.href = data.url
            })
        }
        return [response.status, callback]
    })
}

export async function confirmRecover(code, password) {
    return fetch('/auth/confirm-recover', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            code,
            password,
        })
    }).then(response => {
        const callback = () => {
            if(!response.ok) {
                return response.text()
            }
            response.json().then(data => {
                window.location.href = data.url
            })
        }
        return [response.status, callback]
    })
}
