import jsCookie from "js-cookie";
import React, { useState } from "react";
import { Popup } from "../misc/popup";

export const GlobalContext = React.createContext();

function getDefaultTheme() {
  try {
    const url = new URL(jsCookie.get('target'));
    return url.hostname;
  } catch(e){}
  return ''
}

const GlobalContextProvider = (props) => {

  const [ theme ] = useState(getDefaultTheme());
  const [ hintEmail ] = useState(jsCookie.get('hint_email'));
  const [ serverMsg ] = useState(jsCookie.get('msg'));

  const [ popup, setPopup ] = useState(Popup.NONE);

  return (
    <GlobalContext.Provider
      value={{
        theme,
        hintEmail,
        serverMsg,
        popup,
        setPopup,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;