import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components"
import { keyframes } from "styled-components";
import { GlobalContext } from "../context/globalContext";

const BackgroundDiv = styled.div`
    ${(props) => props.popup !== Popup.NONE && 'filter: brightness(0.5)'}
`

const LoadingIndicatorAnim = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;


const LoadingIndicator = styled.div`
    position: absolute;
    left: calc(50% - 40px);
    top: calc(40% - 40px);
    display: inline-block;
    width: 80px;
    height: 80px;
    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: ${LoadingIndicatorAnim} 1.2s linear infinite;
    }
`

const ErrorMsg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ErrorPopup = styled.div`
    background-color: white;
    padding: 1rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    & > *{
        margin-top: 1rem;
    }
`

export function PopupComp(props) {
    const { popup, setPopup } = useContext(GlobalContext)

    const { t } = useTranslation()

    return (
        <div>
            <BackgroundDiv popup={popup}>
                {props.children}
            </BackgroundDiv>
            {
                popup === Popup.LOADING &&
                    <LoadingIndicator />
            }
            {
                popup === Popup.ERROR && 
                    <ErrorMsg onClick={() => {
                        Popup.ERROR.msg = ''
                        setPopup(Popup.NONE)
                    }}>
                        <ErrorPopup>
                            <span>ERROR</span>
                            <span>{t(Popup.ERROR.msg)}</span>
                        </ErrorPopup>
                    </ErrorMsg>
            }
            {
                popup === Popup.INFO && 
                <ErrorMsg onClick={() => {
                    Popup.INFO.msg = ''
                    setPopup(Popup.NONE)
                }}>
                    <ErrorPopup>
                        <span>{t(Popup.INFO.msg)}</span>
                    </ErrorPopup>
                </ErrorMsg>
            }
        </div>
    )
}

export const Popup = Object.freeze({
    NONE: {},
    LOADING: {},
    LOGGED_IN: {},
    ERROR: {},
    INFO: {},
});
