export async function generateGtagEvent(type, arg) {
    const p = new Promise((resolve) => {
        if (typeof gtag !== 'function') {
            resolve()
            return
        }
        let resolved = false
        // eslint-disable-next-line no-undef
        gtag('event', type, {
            ...arg,
            'event_callback': () => {
                if (!resolved) {
                    resolved = true
                    resolve()
                }
            }
        })
        setTimeout(() => {
            if (!resolved) {
                resolved = true
                resolve()
            }
        }, 1500)
    })
    await p
    return true
}