import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';

import lobbyzeLogo from "../assets/logo_lobbyze.svg"
import slotpokerLogo from "../assets/logo-slotpoker.png"

import Layout from "../components/Layout";
import {
  Container,
  Box,
  InnerBox,
  Title,
  Label,
  Input,
  LogoLobbyze,
  LogoSlotPoker,
  FieldsetEmail,
  VerticalExpander
} from "../styles/globalStyles";
import {
  Body,
  JaCadastrado,
  BoxRegisterGoogle,
  Divider,
  DividerText,
  ContinueButton
} from "../styles/loginStyles";

import { GlobalContext } from '../context/globalContext';
import { generateGtagEvent } from '../misc/analytics'
import { Popup } from '../misc/popup'

const Register = () => {

  const { setPopup } = useContext(GlobalContext)

  const { t } = useTranslation()

  const navigate = useNavigate();
  const [email, setEmail] = useState('')

  function handleClick() {
    navigate('/register-email', { state: email });
  }

  async function onThirdPartyLogin(method) {
    setPopup(Popup.LOADING)
    await generateGtagEvent('login', { method })
    window.location.href = `/auth/oauth/${method}`
  }

  return (
    <Layout>
      <Body>
        <Container>
          <Box>
            <InnerBox>
                <div style={{margin: "0 auto", width: "175px", display: "block"}}>
                  <LogoLobbyze src={lobbyzeLogo} />
                  <LogoSlotPoker src={slotpokerLogo} />
                </div>
                <Title>{t('signup.title')}</Title>
                <FieldsetEmail>
                  <Input
                    type="text"
                    required id="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Label htmlFor="email">{t('signup.email')}</Label>
                </FieldsetEmail>
                <VerticalExpander />
                <ContinueButton onClick={handleClick}>{t('signup.continue')}</ContinueButton>
                <Divider><DividerText>{t('or')}</DividerText></Divider>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <BoxRegisterGoogle href="#" onClick={onThirdPartyLogin.bind(null, 'google')}>{t('signup.google')}</BoxRegisterGoogle>
                </div>
                <JaCadastrado to="/">{t('alreadyHas')}</JaCadastrado>
            </InnerBox>
          </Box>
        </Container>
      </Body>
    </Layout>
  );
};

export default Register;